var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
        width: "60%",
        "append-to-body": "",
        top: "7vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: { model: _vm.formData, "label-width": "120px" },
        },
        [
          _c("div", { staticClass: "dia-tit not-title" }, [
            _c("i"),
            _c("span", {
              domProps: { textContent: _vm._s(_vm.topicDialogTitle) },
            }),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "题库标题：" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入标题" },
                model: {
                  value: _vm.formData.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "title", $$v)
                  },
                  expression: "formData.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "统计明细：" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "mini" },
                  model: {
                    value: _vm.formData.isShow,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "isShow", $$v)
                    },
                    expression: "formData.isShow",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: 1 } }, [
                    _vm._v("展示"),
                  ]),
                  _c("el-radio-button", { attrs: { label: 2 } }, [
                    _vm._v("不展示"),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    display: "inline-block",
                    color: "#ccc",
                    "margin-left": "25px",
                  },
                },
                [_vm._v("（题库控制统计明细是否展示）")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "课程类别：", prop: "catalogId" } },
            [
              _c("el-cascader", {
                attrs: {
                  options: _vm.cascaderList,
                  props: { checkStrictly: true, value: "id", label: "title" },
                },
                model: {
                  value: _vm.formData.catalogId,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "catalogId", $$v)
                  },
                  expression: "formData.catalogId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "题库语言：", prop: "langmask" } },
            [
              _c(
                "el-select",
                {
                  attrs: { multiple: "", placeholder: "请选择" },
                  on: { change: _vm.updateView },
                  model: {
                    value: _vm.formData.langmask,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "langmask", $$v)
                    },
                    expression: "formData.langmask",
                  },
                },
                _vm._l(_vm.langmaskOptions, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c("el-row", [
            _c("div", { staticClass: "dia-tit not-title" }, [
              _c("i"),
              _c("span", [_vm._v("选择题目")]),
            ]),
            _c(
              "div",
              { staticStyle: { display: "flex" } },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "已选题目：" } },
                  [
                    _c(
                      "draggable",
                      {
                        attrs: { options: { animation: 200 } },
                        on: { update: _vm.datadragEnd },
                        model: {
                          value: _vm.formData.seleTopic,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "seleTopic", $$v)
                          },
                          expression: "formData.seleTopic",
                        },
                      },
                      _vm._l(_vm.formData.seleTopic, function (tag, index) {
                        return _c(
                          "el-tag",
                          {
                            key: index,
                            staticStyle: { cursor: "pointer" },
                            attrs: { closable: "", type: "info" },
                            on: {
                              close: function ($event) {
                                return _vm.tagDel(index)
                              },
                            },
                          },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: tag.topicTitle,
                                  placement: "top",
                                },
                              },
                              [_c("span", [_vm._v(_vm._s(tag.topicId))])]
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-button",
                  {
                    staticStyle: { height: "35px" },
                    attrs: {
                      size: "small",
                      type: "info",
                      disabled: _vm.topicDialogTitle === "新增题库",
                    },
                    on: { click: _vm.sortQuestionLibrary },
                  },
                  [_vm._v("排 序")]
                ),
              ],
              1
            ),
          ]),
          _c(
            "el-row",
            [_c("el-form-item", { attrs: { label: "待选题目：" } })],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "label-item-left" },
        [
          _c(
            "el-input",
            {
              staticStyle: { width: "350px", "margin-right": "20px" },
              attrs: { placeholder: "请输入内容" },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.search.apply(null, arguments)
                },
              },
              model: {
                value: _vm.queryData.inputVal,
                callback: function ($$v) {
                  _vm.$set(_vm.queryData, "inputVal", $$v)
                },
                expression: "queryData.inputVal",
              },
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "120px" },
                  attrs: { slot: "prepend" },
                  slot: "prepend",
                  model: {
                    value: _vm.queryData.select,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryData, "select", $$v)
                    },
                    expression: "queryData.select",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "问题标题", value: "1" } }),
                  _c("el-option", { attrs: { label: "问题id", value: "2" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-select",
            {
              staticStyle: { width: "200px", "margin-right": "20px" },
              attrs: { multiple: "", placeholder: "请选择" },
              on: { change: _vm.handleSeleTag },
              model: {
                value: _vm.queryData.seleTag,
                callback: function ($$v) {
                  _vm.$set(_vm.queryData, "seleTag", $$v)
                },
                expression: "queryData.seleTag",
              },
            },
            _vm._l(_vm.tagList, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.title, value: item.id },
              })
            }),
            1
          ),
          _c(
            "el-button",
            {
              attrs: { type: "info", size: "small" },
              on: { click: _vm.search },
            },
            [_vm._v("筛 选")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.reset },
            },
            [_vm._v("重 置")]
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "label-item-left" },
        [
          _c(
            "el-table",
            {
              ref: "multipleTable",
              staticClass: "tb-list",
              attrs: {
                data: _vm.dataList,
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
              on: { "selection-change": _vm.handleSeleChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55", align: "center" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "问题ID",
                  align: "center",
                  width: "200",
                  prop: "problem_id",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "问题标题", align: "left", prop: "title" },
              }),
              _c("el-table-column", {
                attrs: { label: "标签", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        !scope.row.tagList || scope.row.tagList.length === 0
                          ? [_c("span", [_vm._v("无")])]
                          : _vm._l(scope.row.tagList, function (item) {
                              return _c(
                                "span",
                                { key: item.id },
                                [
                                  Boolean(item.groupInfo)
                                    ? [
                                        _c("span", {
                                          staticClass: "tag",
                                          style:
                                            "backgroundColor" +
                                            ":" +
                                            item.groupInfo.color,
                                          domProps: {
                                            textContent: _vm._s(item.title),
                                          },
                                        }),
                                      ]
                                    : [
                                        _c("span", {
                                          staticClass: "tag",
                                          style:
                                            "backgroundColor" + ":" + "#ccc",
                                          domProps: {
                                            textContent: _vm._s(item.title),
                                          },
                                        }),
                                      ],
                                ],
                                2
                              )
                            }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "添加", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.choosetype == 0
                          ? _c(
                              "span",
                              {
                                staticClass: "btn-span",
                                on: {
                                  click: function ($event) {
                                    return _vm.addQues(scope.row, scope.$index)
                                  },
                                },
                              },
                              [_vm._v("添加")]
                            )
                          : _vm._e(),
                        scope.row.choosetype == 1
                          ? _c("span", { staticClass: "btn-hasspan" }, [
                              _vm._v("已添加"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "label-item-left" },
        [
          _c(
            "el-dropdown",
            {
              staticClass: "tb-dropdown",
              staticStyle: { "margin-top": "20px" },
              attrs: { trigger: "click" },
            },
            [
              _c("el-button", { attrs: { type: "info", size: "medium" } }, [
                _vm._v(" 批量操作 "),
                _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
              ]),
              _c(
                "el-dropdown-menu",
                {
                  staticStyle: { padding: "10px" },
                  attrs: { slot: "dropdown" },
                  slot: "dropdown",
                },
                [
                  _c(
                    "el-dropdown-item",
                    {
                      nativeOn: {
                        click: function ($event) {
                          return _vm.enableAccount.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-circle-plus-outline" }),
                      _vm._v("批量添加"),
                    ]
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      nativeOn: {
                        click: function ($event) {
                          return _vm.disabledAccount.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-delete" }),
                      _vm._v("批量删除"),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.pagination.currentPage,
          "page-sizes": _vm.pagination.pageSizes,
          "page-size": _vm.pagination.pageSize,
          layout: _vm.pagination.layout,
          total: _vm.pagination.total,
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
      _c(
        "el-row",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              staticClass: "dialog-btn dialog-left-btn",
              attrs: { type: "primary" },
              on: { click: _vm.addtopic },
            },
            [_vm._v("保 存")]
          ),
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "info" },
              on: { click: _vm.close },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }